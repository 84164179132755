/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/exhaustive-deps */
import theme from '@theme';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Demos } from '@powdr/components';
import { AppContext } from '@powdr/context';
import { useWindowResize } from '@powdr/hooks';
import { getPageTheme } from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';
import GlobalStyle from '@powdr/web/src/utils/global-styles';

const DemoPageWrapper = styled.div``;

function DemoTemplate({ pageContext }) {
  const resize = useWindowResize();
  const { defaultSeason } = useStaticData();
  const [demoSeasonTheme, setDemoSeasonTheme] = useState(getPageTheme(defaultSeason));
  const {
    setIsMobile,
  } = useContext(AppContext);

  useEffect(() => {
    setIsMobile(!!(resize.windowWidth < 960));
  }, [resize.windowWidth, setIsMobile]);

  const changeDemoTheme = (season) => {
    setDemoSeasonTheme(season);
  };

  return (
    <DemoPageWrapper>
      <GlobalStyle theme={demoSeasonTheme} />
      <Demos data={pageContext} changeTheme={changeDemoTheme} />
    </DemoPageWrapper>
  );
}

export default DemoTemplate;

DemoTemplate.propTypes = {
  pageContext: PropTypes.instanceOf(Object).isRequired,
};

DemoTemplate.defaultProps = {

};
